//======> Import Tornado UI Helpers <=======//
import Tornado from './tornado/Tornado';
//======> TinySlider <=======//
import { tns } from "../../node_modules/tiny-slider/src/tiny-slider";
//=====> Slim Select <=====//
import SlimSelect from 'slim-select'

//====> Grid Tiny Slider <====//
const fixTnsGrid = () => {
    var gridTns = Tornado.getElements('.tns-slider.row');
    Array.from(gridTns).forEach(function (gridTns){
        gridTns.closest('.tns-outer').classList.add('grid-tns');
    });
}

document.addEventListener('DOMContentLoaded', event => {
    
    /*======> Main Slider <=======*/
    Tornado.getElements('.main-slider').forEach(element => {
        var theSlider = tns({
            container: element,
            items: 1,
            slideBy: 1,
            autoplay: true,
            nav:false,
            autoplayButton: false,
            autoplayButtonOutput:false,
        });

        if (element.closest('.tns-outer')) element.closest('.tns-outer').classList.add('main-slider-outer');
    });

    /*======> Carousel Slider <=======*/
    Tornado.getElements('.main-carousel').forEach(element => {
        var theSlider = tns({
                container: element,
                items: 1,
                slideBy: 1,
                autoplay: true,
                nav:false,
                autoplayButton: false,
                autoplayButtonOutput:false,
                responsive: {
                    1100 : {items:4},
                    768 : {items:3},
                    500 : {items:2},
                }
            });

        if (element.closest('.tns-outer')) element.closest('.tns-outer').classList.add('main-carousel-outer');
    });

    /*======> Brands Slider <=======*/
    Tornado.getElements('.brands-carousel').forEach(element => {
        var theSlider = tns({
                container: element,
                items: 1,
                slideBy: 1,
                autoplay: true,
                nav:false,
                autoplayButton: false,
                autoplayButtonOutput:false,
                responsive: {
                    1100 : {items:6},
                    768 : {items:4},
                    670 : {items:3},
                    480 : {items:2},
                }
            });

        if (element.closest('.tns-outer')) element.closest('.tns-outer').classList.add('brands-carousel-outer');
    });

    /*======> Gallery Slider <=======*/
    Tornado.getElements('.images-slider').forEach(element => {
        var gallerySlider = tns({
                container: element,
                items: 1,
                slideBy: 1,
                autoplay: true,
                nav:false,
                autoplayButton: false,
                autoplayButtonOutput:false,
            });

        if (element.closest('.tns-outer')) element.closest('.tns-outer').classList.add('images-slider-outer');

        Tornado.getElements('.thumbnails-slider .image').forEach((thumbnail,index) => {
            if(index == 0) thumbnail.parentNode.classList.add('active');
            thumbnail.addEventListener('click', event => {
                gallerySlider.goTo(index);
                event.target.parentNode.classList.add('active');
                Tornado.getSiblings({element:event.target.parentNode}).forEach(sibling => sibling.classList.remove('active'));
            });
        });
    });

    //======> Fix Slider With Grid <======//
    fixTnsGrid();

    /*=====> Form Repeater <=====*/
    Tornado.getElements('.repeater-item .add-item').forEach(element => {
        element.addEventListener('click', event => {
            event.preventDefault();
            /*====> Clone Parent Item <====*/
            var itemClone = Tornado.parentsUntil(event.target, '.repeater-item').cloneNode(true),
                repeater = Tornado.parentsUntil(event.target, '.form-repeater');
            /*====> Reset New Item <====*/
            itemClone.querySelector('.add-item').classList = 'btn ti-minus danger remove-item';
            itemClone.querySelectorAll('input,select,textarea').forEach(element => {
                element.value = '';
                if(element.parentNode.matches('.file-input')) element.parentNode.setAttribute('data-text','اختيار ملف');
            });
            /*====> Repeat the Item <====*/
            Tornado.appendIn(itemClone, repeater);
        });
    });

    /*=====> Hidden Numbers <=====*/
    Tornado.getElements('.hidden-number').forEach(element => {
        //======> Ecrypt the Number <======//
        var numberElement = element.querySelector('.number'),
            numberValue = numberElement.textContent,
            encryptedValue = numberValue.replace(/[1-5]/g,'X');
        numberElement.textContent = encryptedValue;
        //======> Decrypt the Number <======//
        element.addEventListener('click', event => {
            event.preventDefault();
            numberElement.textContent = numberValue;
        });
    });

    /*=====> Advanced Select <======*/
    Tornado.getElements('.advanced-select').forEach((element,index) => {
        //=====> Set a ID for ech Select <=====//
        Tornado.setAttributes(element,{'id':`slime-selector-n${index}`,});
        //=====> Slim Select Init <=====//
        // new SlimSelect({select: `#slime-selector-n${index}`});
    });
});
