//======> Import Tornado UI Helpers <=======//
import Tornado from './Tornado';

/**======> Referance By Comment <======
 * ===> 01 - Nested Multi Level Menu
*/

/*==== Nested Multi Level Menu ====*/
export const nestedMenu = options => {
    var SubMenuList = Tornado.getElements('.nested-menu ul > li > ul');
    Array.from(SubMenuList).forEach(function (SubMenuList) {
        //===> Catch SubMenu Button <===//
        var SubMenuBtn = Tornado.getPrevSibling({element:SubMenuList, filter:'a'});
        SubMenuBtn.classList.add('submenu');
        SubMenuBtn.classList.add('ti-arrow-down-chevron');
        //===> When Click on the Button <===//
        var viewWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        if (viewWidth <= 700) {
            SubMenuBtn.addEventListener('click', event => {
                //==> Prevent Default Behavor <==//
                event.preventDefault();
                //==> Catch Playable Elements <==//
                var thisParent = SubMenuBtn.parentNode,
                    parentSiblings = Tornado.getSiblings({element:thisParent});
                //===> Activated The Clicked Item <===//
                if (thisParent.classList.contains('active')) {
                    thisParent.classList.remove('active');
                    Tornado.slideUp(SubMenuList,500);
                } else {
                    thisParent.classList.add('active');
                    Tornado.slideDown(SubMenuList,500);
                }
                //===> Deativate the Siblings Items <===//
                parentSiblings.forEach(parentSiblings => {
                    var siblingList = parentSiblings.querySelector('ul');
                    Tornado.slideUp(siblingList,500);
                    parentSiblings.classList.remove('active');
                });
            });
        }
    });
}

document.addEventListener('DOMContentLoaded', nestedMenu);
